<template>
    <div>
        <app-search-field
            v-model="filterKey"
            filterLabel="Filter"
            append-icon="mdi-magnify"
            @click:clear="filterKey = ''"
            @keydown.esc="filterKey = ''"
            :clearable="true"
        />
        <v-list v-if="!loading" class="mt-1">
            <div v-if="users.length > 0" class="select-all-users">
                {{ $t('chat-groups.details.members.select-all') }}
                <v-checkbox
                    v-model="selectAll"
                    hide-details
                    class="shrink mr-0 mt-0"
                    :ripple="false"
                    @change="checkAll($event)"
                  ></v-checkbox>
            </div>
            <v-list-item-group v-model="selectedUsers" multiple>
                <template v-for="(item, i) in users">
                    <v-list-item
                        v-show="isMatch(item)"
                        :value="item.id"
                    >
                        <template v-slot:default="{active}">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.display_name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-checkbox :input-value="active"/>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
        <loading v-else :dataLoaded="false"></loading>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import AppSearchField from '@/components/vuetify/AppSearchField'
import loading from '@/components/layouts/Loading'

export default {
    name: "UserMultiSelector",
    components: { AppSearchField, loading },
    props: [
        'value',
        'getUserUrl'
    ],
    data() {
        return {
            filterKey: '',
            selectedUsers: this.value,
            users: [],
            loading: false,
            selectAll: false
        }
    },
    watch: {
        selectedUsers: function (val) {
            this.$emit('input', val)
        },
    },
    async created() {
        await this.loadData()
    },
    methods: {
        async loadData() {
            this.loading = true
            let url = '/users';
            if (this.getUserUrl) {
                url = this.getUserUrl()
            }
            let response = await HTTP.get(url)
            this.users = response.data.sort((a, b) =>
                a.display_name.localeCompare(b.display_name, undefined, {sensitivity: 'accent'})
            )
            this.loading = false
        },
        isMatch(item) {
            return !item.display_name || item.display_name.toLowerCase().includes(this.filterKey.toLowerCase())
        },
        checkAll(checkAll) {
            if (checkAll) {
                this.selectedUsers = this.users.map((user) => user.id )
            } else {
                this.selectedUsers = []
            }
        },
        reload() {
            this.loadData()
        }
    }
}
</script>

<style scoped lang="scss">
.select-all-users {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    padding-bottom: 4px;
    line-height: 32px;

    > .v-input {
        margin-left: 8px;
    }
}
</style>